"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";
import Vue from "vue";
import User from "@/shared/lib/client-sdk/models/user";

class Prospect extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "Prospect";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/prospects"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot create prospects.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/prospects/" +
      this.id
    );
  }

  getAdminPath() {
    console.error("Cannot go to a prospect.");
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.fullName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  /**** HELPER ADDITIONS ********/

  get fullName() {
    return `${this.firstName ? this.firstName + " " : ""}${this.lastName}`;
  }

  static verifyEmailAddress(newEmailAddress, oldEmailAddress, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/prospects/authenticated-account-holder-user/verify-email-address";

    newEmailAddress = newEmailAddress.trim();

    var requestData = {
      newEmailAddress,
      oldEmailAddress,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback))
            callback(response.result, response.message, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static changeEmailAddress(
    newEmailAddress,
    changeEmailAddressToken,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/prospects/authenticated-account-holder-user/change-email-address";

    var requestData = {
      newEmailAddress,
      changeEmailAddressToken,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback))
            callback(response.result, response.message, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static confirmChangeEmailAddressByCode(
    userName,
    emailConfirmationCode,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/prospects/authenticated-account-holder-user/confirm-change-email-address-by-code";

    var requestData = {
      userName: userName,
      emailConfirmationCode: emailConfirmationCode,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if (response.result.authentication) {
            const authToken = response.result.authentication.token;

            Vue.$cookies.set(
              "authToken",
              authToken,
              Infinity,
              "/",
              window.amplifyRuntimeConfig.get("VUE_APP_AMPLIFY_COOKIE_DOMAIN")
            );
          }

          const user = new User(response.result.user);

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static storeFile(file, type) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/prospects/stored-file/" +
      type;

    let formData = new FormData();
    formData.append("file", file);

    return new Promise((resolve, reject) => {
      ApiRequest.upload(requestUrl, formData).onComplete(
        function (response) {
          if (response.successful) {
            resolve(response.result);
          } else {
            reject(response);
          }
        }.bind(this)
      );
    });
  }

  static getStoredFile(id) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/prospects/stored-file/" +
      id;

    return new Promise((resolve, reject) => {
      ApiRequest.send("GET", requestUrl, null).onComplete(
        function (response) {
          if (response.successful) {
            resolve(response.result);
          } else {
            reject(response);
          }
        }.bind(this)
      );
    });
  }

  advisorApprovalAction(action, file) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/prospects/"+this.id+"/advisor-approval/"+action;

    let formData = new FormData();
    formData.append("file", file);

    return new Promise((resolve, reject) => {
      ApiRequest.upload(requestUrl, formData).onComplete(
        function (response) {
          if (response.successful) {
            resolve(response.result);
          } else {
            reject(response);
          }
        }.bind(this)
      );
    });

  }
}

export default Prospect;
